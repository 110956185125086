<template>
    <div class="container rtl">
        <div class="row py-3">
            <div class="col">
                <form @submit.prevent="addcity" autocomplete="off">
                    <div class="row py-3">
                        <div class="col"><span>نرخی دۆلارر</span></div>
                        <div class="btn-group" data-toggle="buttons">
                            <label class="btn btn-primary active">
                                نوێکردنەوە
                                <input type="checkbox" v-model="resetable">
                            </label>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                            <label> نرخی ١ دۆلار</label>
                            <input v-model="city.dollar_price" class="form-control" placeholder=" دۆلار" type="text"
                                required>
                        </div>


                        <div class="col-12">
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary m-2">نوێکردنەوە</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row my-3 border-top">
            <hr>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                selected_city: {},
                city: {},
                resetable: false,
                msg: {
                    color: '',
                    model: false,
                    text: ''
                }
            }
        },

        methods: {

            addcity() {
                let params = this.city
                axios.post('dolar/update.php', params)
                    .then(r => {
                        if (r.data.status == 'ok') {
                            

                            this.msg = {
                                model: true,
                                text: 'دۆلار نوێکرایەوە',
                                color: 'success'
                            }
                            this.$toast(this.msg.text);
                            setTimeout(() => {
                                window.location.reload()
                            }, 1000);
                        } else {
                            alert(r.data.message);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    })
            },

        },
        mounted() {
            this.city.dollar_price = this.$store.state.dollar
        },
    }
</script>